<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 년도 -->
          <c-datepicker
            required
            type="year"
            default="today"
            label="목표년도"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="KPI목표 관리 목록"
      :columns="grid.columns"
      :data="grid.data"
    >
      <template v-slot:customArea="{ props, col }">
        <template>
          <span>
              {{ props.row[col.name] }}
            <q-badge v-if="Number(props.row['kpiTarget'] < Number(props.row[col.name]) && props.row['kpiThanCd'] === 'KC00000001')" color="primary" label="↑" />
            <q-badge v-else-if="Number(props.row['kpiTarget'] > Number(props.row[col.name]) && props.row['kpiThanCd'] === 'KC00000002')" color="red" label="↓" />
          </span>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveData"
            @btnCallback="saveDataCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getData" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-healthy-target',
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
      },
      grid: {
        columns: [
        ],
        data: [],
      },
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      isSave: false,
      saveUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.yearUrl = selectConfig.kpi.target.year.url;
      this.listUrl = selectConfig.kpi.target.list.url;
      this.saveUrl = transactionConfig.kpi.target.save.url;
      // code setting
      this.searchParam.year = this.$comm.getThisYear();
      // list setting
      this.getData();
      this.setHeader();
    },
    setHeader() {
      let _columns = [
        {
          name: 'plantName',
          field: 'plantName',
          label: '사업장',
          align: 'center',
          style: 'width:120px',
          sortable: true,
        },
        {
          name: 'kpiDivision',
          field: 'kpiDivision',
          label: '구분',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'kpi',
          field: 'kpi',
          label: '목표관리 항목',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'kpiCalculation',
          field: 'kpiCalculation',
          label: '산정식(지표)',
          align: 'left',
          style: 'width:250px',
          sortable: false,
        },
        {
          required: true,
          name: 'kpiTarget',
          field: 'kpiTarget',
          label: '목표 지수',
          align: 'right',
          style: 'width:90px',
          sortable: false,
          type: 'number',
        },
        {
          required: true,
          name: 'kpiThanCd',
          field: 'kpiThanCd',
          label: '이상/이하',
          align: 'center',
          style: 'width: 90px',
          sortable: false,
          type: 'select',
          codeGroupCd: 'KPI_CALCULATE_CD',
        },
      ];
      for (let i=1; i<=12; i++) {
        _columns.push({
          name: 'mon' + i,
          field: 'mon' + i,
          label: i+'월',
          style: 'width:60px',
          type: 'custom',
          align: 'center',
        })
      }
      this.grid.columns = _columns;
    },
    getData() {
      if (!this.searchParam.year) {
        this.grid.data = [];
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '목표년도 선택하세요.', // 작업기간을 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        this.getList();
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;

        this.getYearList();
      },);
    },
    getYearList() {
      this.$http.url = this.yearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {

        this.$_.forEach(_result.data, _item => {
          let findData = this.$_.find(this.grid.data, { kpiTypeCd: _item.kpiTypeCd });
          if (!findData) return;
          this.$set(findData, 'plantName', _item.plantName)
          this.$set(findData, 'targetYear', _item.targetYear)
          this.$set(findData, 'kpiTypeCd', _item.kpiTypeCd)
          this.$set(findData, 'kpiDivision', _item.kpiDivision)
          this.$set(findData, 'kpi', _item.kpi)
          this.$set(findData, 'kpiThanCd', _item.kpiThanCd)
          this.$set(findData, 'kpiCalculation', _item.kpiCalculation)
          this.$set(findData, 'kpiTarget', _item.kpiTarget)
        })
      },);
    },
    saveData() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, _item => {
              _item.targetYear = this.searchParam.year
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveDataCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    }
  }
};
</script>
